.typewriter {
  .--type-effect {
    width: fit-content;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 5px solid $darkBlue; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.15em; /* Adjust as needed */
    animation: typing 2s steps(30, end), blink-caret 1s step-end infinite;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 80%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $darkBlue;
  }
}

// .skew-animate-1:hover {
//   transform: skew(-20deg);
//   text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
// }

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// media queries
@media only screen and (min-width: 768px) {
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 35%;
    }
  }
}
