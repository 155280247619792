.hero {
  &--wrapper {
    margin: 0 auto;
    position: relative;
  }

  &--image {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -2;
    object-fit: cover;
    object-position: right;
    animation: fadeIn 1s ease-in;
    transition: all 0.2s;

    &-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $greyBlue;
      opacity: 0.2;
      z-index: -1;
    }
  }
}

/* resume css */
.resume {
  height: fit-content;
  margin: 30px 15px;
  border: 2px solid #292729;
  background-color: #292729;
  box-shadow: 6px 6px 6px 0 #00000033;
  border-radius: 5px;
  font-size: 16px;

  a {
    color: $secondaryYellow;
    text-decoration: none;
  }

  &--link {
    color: $secondaryYellow;
    position: relative;
    text-decoration: none;
    display: inline-block;
    padding: 0 1px;
    transition: color ease 0.3s;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 0;
      height: 100%;
      left: 0;
      bottom: 0;
      background-color: $beige;
      transition: all ease 0.5s;
      opacity: 0.75;
    }

    &:hover {
      color: $white;

      &::after {
        width: 100%;
      }
    }
  }

  .fa-download {
    margin-left: 5px;
  }

  &--header {
    height: 50px;
    background-color: $greyBlue;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      color: $secondaryBlue;
      -webkit-text-fill-color: $secondaryBlue;
      margin-right: 5px;
    }
  }
}

.indent {
  margin: 10px 0px 10px 40px;
  letter-spacing: 3px;

  &--double {
    margin-left: 60px;
    margin-top: 10px;
    letter-spacing: 2px;
  }
}

.ml-20 {
  margin-left: -20px;
}

// media queries
@media only screen and (min-width: 768px) {
  .resume {
    margin: 40px;
    font-size: 20px;

    &--body {
      padding: 20px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .resume {
    font-size: 25px;
    margin: 70px;
  }
}
