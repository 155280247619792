.logo {
  font-family: $amatic;
  font-size: 60px;
  letter-spacing: 5px;
}

.nav-link {
  font-family: $notoSerif;
  letter-spacing: 3px;

  &::after {
    display: block;
    content: '';
    padding-bottom: 4px;
    border-bottom: 2px solid $dirtyPink;
    transform: scaleX(0);
    transition: transform 250ms linear 0s;
  }

  &:hover::after {
    transform: scaleX(1);
  }
}

// media queries
@media only screen and (max-width: 768px) {
  .nav-link:hover::after {
    transform: scaleX(0);
  }
}

/* override default nav styles */
.navbar {
  padding: 1rem;

  button:focus {
    outline: none;
  }
}

.bg-light {
  background-color: $silkWhite !important;
}
