.project-card {
  height: 250px;
  border: 15px solid $silkWhite;
  background-color: $silkWhite;
  border-radius: 3px;
  background-size: cover;
  display: block;
  position: relative;
  transition: 0.3s;

  img {
    width: 100%;
    height: 100%;
    transition: 0.3s;
    border-radius: 4px;
  }

  h4 {
    font-family: $dawningDay;
    font-size: 16px;
    position: absolute;
    z-index: 1;
    top: 60%;
    width: 100%;
    text-align: center;
    color: $silkWhite;
    opacity: 0;
    transition: 0.3s;
  }

  h5 {
    font-family: $notoSerif;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    top: 20%;
    width: 100%;
    text-align: center;
    color: $silkWhite;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover img {
    filter: blur(4px) brightness(60%);
    box-shadow: 0 0 16px #e4cee7;
  }

  &:hover :not(img) {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 5px 10px #0000001a, 0 6px 6px #00000033;
    cursor: pointer;
  }
}

/* media queries */
@media only screen and (min-width: 768px) {
  .project-card {
    h4 {
      font-size: 45px;
    }
    h5 {
      font-size: 22px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .project-card {
    height: 300px;
  }
}
