body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-pink {
  color: #eab0d9; }

.secondary-pink {
  color: #dab8f3; }

.primary-blue {
  color: #b9cced; }

.primary-yellow {
  color: #ffd3b6; }

.white {
  color: #ffffff; }

body {
  font-family: 'Alegreya Sans SC';
  font-size: 16px;
  line-height: 30px;
  background-color: #d8cfd0; }

html,
body {
  overflow-x: hidden; }

@media only screen and (min-width: 768px) {
  body {
    font-size: 20px; } }

@media only screen and (min-width: 1200px) {
  body {
    font-size: 25px; } }

/* Animations */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.hero--animation {
  -webkit-animation: fadeIn 2s ease-in;
          animation: fadeIn 2s ease-in;
  transition: all 0.2s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.gold {
  color: #c3a343;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  background-image: url(/static/media/goldLettering.d5cc4bfe.jpg);
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text; }

.resume--gold-header {
  position: absolute;
  font-size: 16px;
  font-family: "Dancing Script", cursive;
  letter-spacing: 2px;
  padding: 10px 0px;
  cursor: pointer; }

.resume--header ::selection {
  background-color: transparent; }

.resume--header .resume--gold-header a:hover {
  opacity: initial; }

.hero--text-overlay {
  font-family: "Dawning of a new day", cursive;
  padding: 15px;
  font-size: 28px;
  letter-spacing: 4px;
  position: absolute;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
  width: 70%; }

.label {
  color: white;
  margin-left: 10px;
  margin-top: 10px;
  letter-spacing: 4px;
  font-weight: 600; }

::selection {
  background-color: #40618c;
  color: #f2f1ef; }

/* media queries */
@media only screen and (max-width: 768px) {
  .resume--gold-header {
    letter-spacing: 1px; } }

@media only screen and (min-width: 768px) {
  .resume--gold-header {
    font-size: 30px; }
  .hero--text-overlay {
    font-size: 48px;
    margin-top: 40px;
    margin-left: 15px; } }

@media only screen and (min-width: 1024px) {
  .hero--text-overlay {
    font-size: 55px;
    margin-left: 30px; } }

@media only screen and (min-width: 1200px) {
  .hero--text-overlay {
    margin-top: 100px;
    font-size: 65px;
    width: 60%; } }

.typewriter .\--type-effect {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 5px solid #40618c;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  -webkit-animation: typing 2s steps(30, end), blink-caret 1s step-end infinite;
          animation: typing 2s steps(30, end), blink-caret 1s step-end infinite; }

@-webkit-keyframes typing {
  from {
    width: 0; }
  to {
    width: 80%; } }

@keyframes typing {
  from {
    width: 0; }
  to {
    width: 80%; } }

@-webkit-keyframes blink-caret {
  from,
  to {
    border-color: transparent; }
  50% {
    border-color: #40618c; } }

@keyframes blink-caret {
  from,
  to {
    border-color: transparent; }
  50% {
    border-color: #40618c; } }

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media only screen and (min-width: 768px) {
  @-webkit-keyframes typing {
    from {
      width: 0; }
    to {
      width: 35%; } }
  @keyframes typing {
    from {
      width: 0; }
    to {
      width: 35%; } } }

.hero--wrapper {
  margin: 0 auto;
  position: relative; }

.hero--image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: cover;
  object-position: right;
  -webkit-animation: fadeIn 1s ease-in;
          animation: fadeIn 1s ease-in;
  transition: all 0.2s; }
  .hero--image-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #697184;
    opacity: 0.2;
    z-index: -1; }

/* resume css */
.resume {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 30px 15px;
  border: 2px solid #292729;
  background-color: #292729;
  box-shadow: 6px 6px 6px 0 #00000033;
  border-radius: 5px;
  font-size: 16px; }
  .resume a {
    color: #f3f59b;
    text-decoration: none; }
  .resume--link {
    color: #f3f59b;
    position: relative;
    text-decoration: none;
    display: inline-block;
    padding: 0 1px;
    transition: color ease 0.3s;
    z-index: 1; }
    .resume--link::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 0;
      height: 100%;
      left: 0;
      bottom: 0;
      background-color: #b1a6a4;
      transition: all ease 0.5s;
      opacity: 0.75; }
    .resume--link:hover {
      color: #ffffff; }
      .resume--link:hover::after {
        width: 100%; }
  .resume .fa-download {
    margin-left: 5px; }
  .resume--header {
    height: 50px;
    background-color: #697184;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; }
    .resume--header-icon {
      color: #69a5f3;
      -webkit-text-fill-color: #69a5f3;
      margin-right: 5px; }

.indent {
  margin: 10px 0px 10px 40px;
  letter-spacing: 3px; }
  .indent--double {
    margin-left: 60px;
    margin-top: 10px;
    letter-spacing: 2px; }

.ml-20 {
  margin-left: -20px; }

@media only screen and (min-width: 768px) {
  .resume {
    margin: 40px;
    font-size: 20px; }
    .resume--body {
      padding: 20px; } }

@media only screen and (min-width: 1200px) {
  .resume {
    font-size: 25px;
    margin: 70px; } }

.contact--container {
  overflow: hidden; }
  .contact--container .lottie-animation {
    width: 200px;
    display: block;
    margin: 0 auto; }

.contact--copy {
  font-family: "Dawning of a new day", cursive;
  text-align: center;
  margin: 100px 0; }
  .contact--copy i:hover {
    color: #40618c;
    outline: none; }
  .contact--copy h1 {
    font-size: 75px;
    letter-spacing: 2px;
    color: #413f3d; }

.contact--social-container {
  margin: 50px 0; }

.email i,
.social--grid i {
  color: #6186b6; }

.email i {
  margin: 20px;
  font-size: 40px; }
  .email i:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
    transition: all 0.2s; }

.social--grid {
  margin-top: 50px;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  row-gap: 20px; }
  .social--grid i {
    font-size: 20px; }
  .social--grid h2 {
    font-size: 16px;
    letter-spacing: 3px;
    color: #413f3d;
    font-family: 'Noto Serif TC', serif; }
  .social--grid a:hover {
    text-decoration: none; }
    .social--grid a:hover i {
      color: #40618c;
      outline: none;
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
      transition: all 0.2s; }

.social--copy {
  padding: 10px; }

/* media queries */
@media only screen and (min-width: 768px) {
  .social--grid {
    grid-template-columns: repeat(2, 1fr); }
    .social--grid h2 {
      font-size: 20px; }
    .social--grid i {
      font-size: 35px; }
  .email i {
    font-size: 50px; } }

@media only screen and (max-width: 768px) {
  .contact--copy {
    width: 100%; }
    .contact--copy h1 {
      font-size: 40px; } }

.logo {
  font-family: "Amatic SC", cursive;
  font-size: 60px;
  letter-spacing: 5px; }

.nav-link {
  font-family: "Noto Serif TC", serif;
  letter-spacing: 3px; }
  .nav-link::after {
    display: block;
    content: '';
    padding-bottom: 4px;
    border-bottom: 2px solid #d8cfd0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 250ms linear 0s;
    transition: transform 250ms linear 0s;
    transition: transform 250ms linear 0s, -webkit-transform 250ms linear 0s; }
  .nav-link:hover::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

@media only screen and (max-width: 768px) {
  .nav-link:hover::after {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); } }

/* override default nav styles */
.navbar {
  padding: 1rem; }
  .navbar button:focus {
    outline: none; }

.bg-light {
  background-color: #f2f1ef !important; }

.portfolio--section {
  margin: 100px 0; }

.portfolio--grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  row-gap: 20px;
  padding: 20px;
  max-width: 1600px;
  margin: 0 auto; }

.portfolio--header {
  font-family: "Dawning of a new day", cursive;
  font-size: 50px;
  margin: 0 auto;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 20px; }

@media only screen and (min-width: 768px) {
  .portfolio--grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px; }
  .portfolio--header {
    font-size: 60px; } }

@media only screen and (min-width: 1200px) {
  .portfolio--grid-container {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
    grid-row-gap: 30px;
    row-gap: 30px; }
  .portfolio--header {
    font-size: 75px; } }

.project-card {
  height: 250px;
  border: 15px solid #f2f1ef;
  background-color: #f2f1ef;
  border-radius: 3px;
  background-size: cover;
  display: block;
  position: relative;
  transition: 0.3s; }
  .project-card img {
    width: 100%;
    height: 100%;
    transition: 0.3s;
    border-radius: 4px; }
  .project-card h4 {
    font-family: "Dawning of a new day", cursive;
    font-size: 16px;
    position: absolute;
    z-index: 1;
    top: 60%;
    width: 100%;
    text-align: center;
    color: #f2f1ef;
    opacity: 0;
    transition: 0.3s; }
  .project-card h5 {
    font-family: "Noto Serif TC", serif;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    top: 20%;
    width: 100%;
    text-align: center;
    color: #f2f1ef;
    opacity: 0;
    transition: 0.3s; }
  .project-card:hover img {
    -webkit-filter: blur(4px) brightness(60%);
            filter: blur(4px) brightness(60%);
    box-shadow: 0 0 16px #e4cee7; }
  .project-card:hover :not(img) {
    opacity: 1; }
  .project-card:hover {
    box-shadow: 0 5px 10px #0000001a, 0 6px 6px #00000033;
    cursor: pointer; }

/* media queries */
@media only screen and (min-width: 768px) {
  .project-card h4 {
    font-size: 45px; }
  .project-card h5 {
    font-size: 22px; } }

@media only screen and (min-width: 1200px) {
  .project-card {
    height: 300px; } }

