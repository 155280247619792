$dawningDay: 'Dawning of a new day', cursive;
$dancingScript: 'Dancing Script', cursive;
$notoSerif: 'Noto Serif TC', serif;
$amatic: 'Amatic SC', cursive;
$alegreya: 'Alegreya Sans SC';


// ABOUT
/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hero--animation {
  animation: fadeIn 2s ease-in;
  transition: all 0.2s;
  animation-delay: 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.gold {
  color: #c3a343;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  background-image: url(./assets/goldLettering.jpg);
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
}

.resume--gold-header {
  position: absolute;
  font-size: 16px;
  font-family: $dancingScript;
  letter-spacing: 2px;
  padding: 10px 0px;
  cursor: pointer;
}

.resume--header {
  ::selection {
    background-color: transparent;
  }

  .resume--gold-header a:hover {
    opacity: initial;
  }
}

.hero--text-overlay {
  font-family: $dawningDay;
  padding: 15px;
  font-size: 28px;
  letter-spacing: 4px;
  position: absolute;
  transform: rotate(-5deg);
  width: 70%;
}

.label {
  color: white;
  margin-left: 10px;
  margin-top: 10px;
  letter-spacing: 4px;
  font-weight: 600;
}

::selection {
  background-color: $darkBlue;
  color: $silkWhite;
}

/* media queries */
@media only screen and (max-width: 768px) {
  .resume--gold-header {
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 768px) {
  .resume--gold-header {
    font-size: 30px;
  }

  .hero--text-overlay {
    font-size: 48px;
    margin-top: 40px;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 1024px) {
  .hero--text-overlay {
    font-size: 55px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .hero--text-overlay {
    margin-top: 100px;
    font-size: 65px;
    width: 60%;
  }
}
