body {
  font-family: 'Alegreya Sans SC';
  font-size: 16px;
  line-height: 30px;
  background-color: $dirtyPink;
}

html,
body {
  overflow-x: hidden;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  body {
    font-size: 25px;
  }
}