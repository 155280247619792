.portfolio {
  &--section {
    margin: 100px 0;
  }
  &--grid-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px;
    max-width: 1600px;
    margin: 0 auto;
  }

  &--header {
    font-family: $dawningDay;
    font-size: 50px;
    margin: 0 auto;
    text-align: center;
    width: fit-content;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

// media queries
@media only screen and (min-width: 768px) {
  .portfolio {
    &--grid-container {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
    }

    &--header {
      font-size: 60px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .portfolio {
    &--grid-container {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;
      row-gap: 30px;
    }
    &--header {
      font-size: 75px;
    }
  }
}
