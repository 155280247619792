.contact {
  &--container {
    overflow: hidden;

    .lottie-animation {
      width: 200px;
      display: block;
      margin: 0 auto;
    }
  }

  &--copy {
    font-family: $dawningDay;
    text-align: center;
    margin: 100px 0;

    i:hover {
      color: $darkBlue;
      outline: none;
    }

    h1 {
      font-size: 75px;
      letter-spacing: 2px;
      color: $lightBlack;
    }
  }

  &--social-container {
    margin: 50px 0;
  }
}

.email i,
.social--grid i {
  color: $iconBlue;
}

.email i {
  margin: 20px;
  font-size: 40px;

  &:hover {
    transform: scale(1.2);
    text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
    transition: all 0.2s;
  }
}

.social {
  &--grid {
    margin-top: 50px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;

    i {
      font-size: 20px;
    }

    h2 {
      font-size: 16px;
      letter-spacing: 3px;
      color: $lightBlack;
      font-family: 'Noto Serif TC', serif;
    }

    a:hover {
      text-decoration: none;

      i {
        color: $darkBlue;
        outline: none;
        transform: scale(1.2);
        text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
        transition: all 0.2s;
      }
    }
  }

  &--copy {
    padding: 10px;
  }
}

/* media queries */
@media only screen and (min-width: 768px) {
  .social {
    &--grid {
      grid-template-columns: repeat(2, 1fr);

      h2 {
        font-size: 20px;
      }

      i {
        font-size: 35px;
      }
    }
  }

  .email i {
    font-size: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .contact--copy {
    width: 100%;

    h1 {
      font-size: 40px;
    }
  }
}
