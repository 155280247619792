//resume text colors
$primaryPink: #eab0d9;
$secondaryPink: #dab8f3;
$highlightPink: #e9add5;
$primaryBlue: #b9cced;
$secondaryBlue: #69a5f3;
$primaryYellow: #ffd3b6;
$secondaryYellow: #f3f59b;
$white: #ffffff;

// theme colors
$dirtyPink: #d8cfd0;
$beige: #b1a6a4;
$greyBlue: #697184;
$silkWhite: #f2f1ef;
$lightBlack: #413f3d;
$darkBlue: #40618c;
$iconBlue: #6186b6;

.primary-pink {
  color: $primaryPink;
}

.secondary-pink {
  color: $secondaryPink;
}

.primary-blue {
  color: $primaryBlue;
}

.primary-yellow {
  color: $primaryYellow;
}

.white {
  color: $white;
}
